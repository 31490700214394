import Masonry from 'masonry-layout';
// eslint-disable-next-line no-unused-vars
import imagesLoaded from 'imagesloaded';
// eslint-disable-next-line camelcase
export default function () {
  if (document.getElementsByClassName('grid--packery').length) {
    const packeryGrid = new Masonry(
      '.grid--packery', {
        itemSelector: '.grid__item',
        gutter: 0,
        horizontalOrder: false,
      },
    );
    // eslint-disable-next-line no-undef
    $('.grid--packery').imagesLoaded().progress(() => {
      packeryGrid.layout();
    });
  }
}
