/* eslint-disable */
export const floatingLabels = () => {
  if (document.createElement('input').placeholder === undefined) {
    $('.floating-label-container input, .floating-label-container textarea').addClass('active');
    $('.floating-label-container label').addClass('active');
  } else {
    $('.floating-label-container').each((index, element) => {
      $(element).find('input, textarea').on('select keydown', (event) => {
        const e = event.currentTarget;
        $(e).parent().find('label').addClass('active');
        $(e).addClass('active');
      });
      $(element).find('input, textarea').on('keyup blur ', (event) => {
        const e = event.currentTarget;
        if ($(e).parent().find('input, textarea').val().length === 0) {
          $(e).parent().find('label').removeClass('active');
          $(e).removeClass('active');
        }
      });
    });
  }
};
/* eslint-enable */
