/* eslint-disable */

import skrollr from 'skrollr';
import waypoint from 'waypoints/lib/jquery.waypoints.min';
import {isMobile} from '../utils/isMobile';
import {matchMobile} from '../constants/defaults';

export default class Parallax {
//window.parallax = window.parallax || {};

  constructor() {
    if (matchMobile.matches) {
      this.setupParallax();
    }

    matchMobile.addListener((mediaQuery) => {
      if (mediaQuery.matches) {
        this.setupParallax();

      }
    });
  };

  setupParallax () {
// only do skrollr if not moAbile
    if (!isMobile) {
      this.renderElements();

      //init skrollr
      const s = skrollr.init({
        smoothScrolling: true,
        forceHeight: false,
        smoothScrollingDuration: 500
      });
      //refresh skrollr on window resize
      $(window).resize(() => {
        s.refresh();
      });
    }
    //init waypoints
    this.makeWaypoints();
  }

  renderElements() {
    const $skrollrItems = $('.skrollr');
    for (let i = 0, j = $skrollrItems.length; i < j; i++) {
      if ($($skrollrItems[i]).hasClass('lazyload')) {

      }
      this.setAnimations($($skrollrItems[i]));
    }
    ;
  };

  setAnimations(el) {

    const sizes = {
      'extrasmall': 10,
      'small': 15,
      'medium': 20,
      'large': 30,
      'xlarge': 70
    };

    // standard parallax animations
    for (let index in sizes) {

      //top to bottom
      if (el.hasClass("skrollr-top-to-bottom-" + index)) {
        el.attr("data-top-bottom", "transform[sqrt]:translate3d(0, " + sizes[index] + "px, 0);");
        el.attr("data-bottom-top", "transform[sqrt]:translate3d(0, -" + sizes[index] + "px, 0);");
      }

      // bottom to top
      if (el.hasClass("skrollr-bottom-to-top-" + index)) {
        el.attr("data-top-bottom", "transform[sqrt]:translate3d(0, -" + sizes[index] + "px, 0);");
        el.attr("data-bottom-top", "transform[sqrt]:translate3d(0, " + sizes[index] + "px, 0);");
      }
    }

    // background animations
    $(window).resize(() => {

      for (let index in sizes) {

        // top to bottom
        if (el.hasClass("skrollr-bg-top-to-bottom-" + index)) {

          this.createWrap(el);
          this.scaleElement(el, index, sizes);

          el.attr("data-top-bottom", "transform[sqrt]:translate3d(0, " + sizes[index] + "px, 0);");
          el.attr("data-bottom-top", "transform[sqrt]:translate3d(0, -" + sizes[index] + "px, 0);");

        }

        //bottom to top
        if (el.hasClass("skrollr-bg-bottom-to-top-" + index)) {

          this.createWrap(el);
          this.scaleElement(el, index, sizes);

          el.attr("data-top-bottom", "transform[sqrt]:translate3d(0, -" + sizes[index] + "px, 0);");
          el.attr("data-bottom-top", "transform[sqrt]:translate3d(0, " + sizes[index] + "px, 0);");

        }

      }

    });

    $(window).trigger('resize');

  };

  makeWaypoints() {

    $('.skrollr.animate').waypoint(() => {

        if ($(this.element).hasClass('fadeIn')) {
          $(this.element).addClass('show');
        }

      },
      {
        offset: '85%'
      });
  };

  createWrap(el) {

    if (!el.parents('.overflow-wrap').length) {

      el.wrap("<div class='overflow-wrap'></div>");
      el.wrap("<div class='scale-wrap'></div>");

    }

  };

  scaleElement(el, indexParam, sizes) {

    // calculate how much scaling is needed
    let scaleVal = 1.0;

    for (let index in sizes) {

      if (index == indexParam) {

        const initHeight = el.outerHeight(),
          extraSize = 2 * sizes[index],
          newHeight = initHeight + extraSize;

        scaleVal = newHeight / initHeight;
        scaleVal = scaleVal * scaleVal - (scaleVal - 1) / 2;

      }
    }

    //scale the wrap
    el.parents(".scale-wrap").css({
      '-webkit-transform': 'scale(' + scaleVal + ')',
      '-moz-transform': 'scale(' + scaleVal + ')',
      '-ms-transform': 'scale(' + scaleVal + ')',
      '-o-transform': 'scale(' + scaleVal + ')',
      'transform': 'scale(' + scaleVal + ')'
    });

  };
};

/* eslint-enable */
