import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import slick from 'slick-carousel';

export default function () {
  $.each($('.slick-slider-wrap'), (index, el) => {
    const $sliderWrap = $(el);
    const $slider = $sliderWrap.find('.slick-slider');
    $slider.on('init', () => {
    });

    $slider.slick({
      centerMode: false,
      infinite: false,
      centerPadding: '0px',
      slidesToShow: 4,
      arrows: false,
      nextArrow: '#slider-next',
      prevArrow: '#slider-prev',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    });

    $sliderWrap.find('#slider-next').on('click', () => {
      $slider.slick('slickNext');
    });
    $sliderWrap.find('#slider-prev').on('click', () => {
      $slider.slick('slickPrev');
    });
  });
}
