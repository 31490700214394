import { form } from '../components/forms/form';
import BasicGoogleMap from '../components/maps/BasicGoogleMap';

export default class Contact {
  constructor() {
    form('#form-contact');

    const map = new BasicGoogleMap();
    map.init();
  }
}
