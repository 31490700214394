import $ from 'jquery';
import Video, { videos } from '@esign/video';

import navigation from './components/layout/navigation';
import packery from './components/layout/packery';
import Lazy from 'jquery-lazy';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import gaTracking from './components/tracking/gatracking';
import {floatingLabels} from './utils/floatingLabels';
import imgSlider from './components/layout/imgSlider';
import scrollReveal from './components/layout/scrollReveal';
import Parallax from './utils/parallax';
import fancyBox from './components/layout/fancybox';
import Contact from './pages/Contact';
import Job from './pages/Job';

require('./utils/nativeConsole');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
$('.lazyload').Lazy({
  effect: 'fadeIn',
  afterLoad: (element) => {
    $(element).addClass('loaded');
  },
});
imgSlider();
scrollReveal();
$('.js-input-file').bind('change', (e) => {
  let fileName = e.target.files[0].name;
  $('#file-selected').html(fileName);
});
// eslint-disable-next-line no-unused-vars
const parallaxEffect = new Parallax();
setTimeout(() => {
  packery();
}, 1000);

fancyBox();
// Forms
validation();
floatingLabels();

// Tracking
gaTracking();

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

const videoEls = document.querySelectorAll('.js-video');
[...videoEls].forEach(item => new Video(item));

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  Job,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName]();
  }
}
