import $ from 'jquery';
import { matchNavigation } from '../../constants/defaults';

export default function () {
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  function checkOutsideClick(e) {
    const $subnavContainer = $('.subnav');
    const $subnavNavEl = $('.main-nav__item--with-subnav');
    const container = $subnavContainer;
    if (!$(e.target).parents('a').hasClass('subnav__item__nolink')) {
      if (container.has(e.target).length === 0) {
        $subnavNavEl.removeClass('open');
        $subnavNavEl.find('.subnav').removeClass('subnav--active');
        $(document).unbind('mouseup', checkOutsideClick);
        if (isTouchDevice) {
          $(document).unbind('touchstart', checkOutsideClick);
        }
      }
    }
  }
  const $nav = $('.main-nav__wrap');
  $('.main-nav__trigger').click((e) => {
    e.preventDefault();
    $('.hamburger').toggleClass('hamburger-open');
    $(e.currentTarget).next('.main-nav__wrap').slideToggle('fast');
  });
  $('.main-nav__item--with-subnav .subnav__item__nolink').click((e) => {
    e.preventDefault();
    const $el = $(e.currentTarget);
    const $elLi = $el.parents('.main-nav__item--with-subnav');
    $elLi.toggleClass('open');
    $elLi.find('.subnav').toggleClass('subnav--active');
    if ($elLi.hasClass('open')) {
      $(document).on('mouseup', checkOutsideClick);
    } else {
      $(document).unbind('mouseup', checkOutsideClick);
    }
  });
  matchNavigation.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $nav.css('display', 'flex');
    } else {
      $nav.css('display', 'none');
    }
  });

  let lastScrollTop = 0;
  const $header = $('.page-header');

  $(window).scroll(() => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      $header.addClass('collapsed');
    }
    if (st < 50) {
      $header.removeClass('collapsed');
    }
    lastScrollTop = st;
  });
}
