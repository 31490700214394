// Docs: https://docs.esign.eu/books/development/page/front-end-template#bkmrk-basicgooglemap

import googleMapsStyles from '../../constants/googleMapsStyles';

const defaultMapData = {
  holderId: 'map',
  title: 'Trafiroad',
  lat: 51.097929,
  long: 3.912645,
  externUrl: 'https://www.google.be/maps/place/Trafiroad+nv/@51.097926,3.9092277,17z/data=!4m5!3m4!1s0x47c39cc38405e709:0x919aced1801a6ff!8m2!3d51.0977241!4d3.9125738',
};

export default class BasicGoogleMap {
  init(mapSettings = null) {
    const google = window.google || {};
    this.googleMaps = google.maps;
    this.baseUrl = window.baseUrl || '';

    // override default map data if param is set
    const mapData = mapSettings || defaultMapData;
    const holder = document.getElementById(mapData.holderId);

    if (holder) {
      const map = this.addMap(holder, mapData.lat, mapData.long);
      this.addMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
    }
  }

  addMap(holder, latitude, longitude) {
    const zoom = 16;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(
      googleMapsStyles,
      { name: 'Styled Map' },
    );
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    this.googleMaps.event.addDomListener(window, 'resize', () => {
      map.setCenter(mapCenter);
    });

    return map;
  }

  addMarker(map, latitude, longitude) {
    const myLatlng = new this.googleMaps.LatLng(latitude, longitude);
    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map,
      opacity: 0,
    });

    const contentString = '<div class="infoboxMarker"><p>Nieuwe Dreef 17, Lokeren</p></div>';

    const infowindow = new this.googleMaps.InfoWindow({
      content: contentString,
      pixelOffset: new this.googleMaps.Size(80, 40),
    });
    infowindow.open(map, marker);
    /* this.googleMaps.event.addListener(marker, 'click', () => {
      infowindow.open(map, marker);
    }); */

    return marker;
  }
}
