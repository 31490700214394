import $ from 'jquery';
import fancybox from '@fancyapps/fancybox';
import cookies from 'js-cookie';

export default function () {
  if($('body').hasClass('home-index')) {
    const randomLang = Math.round(Math.random()) ? 'nl' : 'fr';

    $(document).ready(function () {

      $('.js-fancybox-href').click(function(){
        window.location.href = this.dataset.href
      })

      $("#popup-corona").fancybox({
          'overlayShow': true,
          'touch': false,
      }).trigger('click');
    });

    const showPopup = (lang) => {
      $('[data-fancybox]').fancybox({
        afterClose: function (instance, slide) {
          cookies.set(`popup-holidays-2019-${lang}`, 'true');
        }
      });

      $(`.js-popup-${lang}`).show();
      $('#fancybox-trigger').trigger('click');
    }

    if (cookies.get('popup-holidays-2019-fr') !== 'true' && cookies.get('popup-holidays-2019-nl') !== 'true') {
      showPopup(randomLang);
    }

    if (cookies.get('popup-holidays-2019-fr') === 'true' && cookies.get('popup-holidays-2019-nl') !== 'true') {
      showPopup('nl');
    }

    if (cookies.get('popup-holidays-2019-fr') !== 'true' && cookies.get('popup-holidays-2019-nl') === 'true') {
      showPopup('fr');
    }

    $('.close-modal').click(() => {
      $.fancybox.close();
    });
  }
}
