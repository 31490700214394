const serialize = require('form-serialize');
const EventEmitter = require('events');

let recaptchaOnLoadCallbackExposed = false;
let recaptchaOnLoadCallbackCalled = false;
let recaptchaScriptTagEnabled = false;
let recaptchaScriptTagSelector = '#script-recaptcha';
let csrfTokenFetched = false;
let csrfHeaders = {};

// The HTTP method to get the CSRF token by
const csrfMethod = 'GET';

// The endpoint to get the CSRF token from
const csrfEndpoint = 'stateful/csrf';

// Indicates if a CSRF fetch is in progress
let csrfFetchInProgress = false;

const forms = [];

class Form extends EventEmitter {
  constructor(form, options = {}) {
    super();
    const {
      // selector to query submit buttons
      buttonSelector = '[type="submit"]',

      // CSS class to add to submit button pending form submission
      buttonLoadingClass = 'button--loading',

      // General catch-all error message
      generalErrorMessage = 'Something went wrong. Please try again later.',

      // General success message
      generalSuccessMessage = 'Thank you for your message. We will process this as soon as possible.',

      // Whether you need a csrf Token in your header
      fetchCsrf = false,

      // Whether you need the request body to be JSON encoded
      jsonEncodedRequest = true,

      // Whether a GTM event should be fired
      propagateGtm = true,

      // Whether this form requires recaptcha validation
      recaptcha = true,

      // Selector to query the recaptcha container element
      recaptchaClass = 'g-recaptcha',

      // Key under which we should send the recaptcha response to the server
      recaptchaName = 'g-recaptcha-response',

      // Whether the form should be replaced by the success message rather than displaying it above the form
      replaceFormOnSuccess = true,

      // Selector to query the container in which to put the success/error response
      resultSelector = '.result',

      // Send the request asynchronously
      xhr = true,
    } = options;
    this.active = false;
    this.buttonLoadingClass = buttonLoadingClass;
    this.buttonSelector = buttonSelector;
    this.generalErrorMessage = generalErrorMessage;
    this.generalSuccessMessage = generalSuccessMessage;
    this.propagateGtm = propagateGtm;
    this.recaptcha = recaptcha;
    this.recaptchaCallbackName = Form.uniqueRecaptchaCallbackName();
    this.recaptchaClass = recaptchaClass;
    this.recaptchaName = recaptchaName;
    this.recaptchaWidgetId = undefined;
    this.replaceFormOnSuccess = replaceFormOnSuccess;
    this.resultSelector = resultSelector;
    this.xhr = xhr;
    this.fetchCsrf = fetchCsrf;
    this.jsonEncodedRequest = jsonEncodedRequest;

    if (!this.xhr && this.fetchCsrf) {
      throw new Error("fetchCsrf can't be true if xhr is false");
    }

    if (form instanceof HTMLElement) {
      this.form = form;
    } else {
      this.form = document.querySelector(form);
    }

    // set default value for dataLayer which is used in the propagateGtmEvent function
    if (this.propagateGtm) {
      window.dataLayer = window.dataLayer || [];
    }

    // There are two kinds of callbacks:
    // 1. General callback that's called when the recaptcha source code has finished loading. It's a global function
    //    shared by all instances. (recaptchaOnLoadCallback)
    // 2. Callback being called when one of the recaptcha instances has been executed. Every instance has it's own.
    //    (recaptchaCallback)

    if (this.recaptcha) {
      this.exposeRecaptchaCallback();
      this.bindRecaptchaCallback();
    }

    this.bindListeners();

    // We need to keep track of all Form instance. That's why we're registering them statically, which is just pushing
    // them on an array.
    Form.registerForm(this);

    if (this.recaptcha) {
      Form.exposeRecaptchaOnLoadCallback();
    }
  }

  // Getters & setters

  static get recaptchaOnLoadCallbackCalled() {
    return recaptchaOnLoadCallbackCalled;
  }

  static set recaptchaOnLoadCallbackCalled(value) {
    recaptchaOnLoadCallbackCalled = value;
  }

  static get recaptchaOnLoadCallbackExposed() {
    return recaptchaOnLoadCallbackExposed;
  }

  static set recaptchaOnLoadCallbackExposed(value) {
    recaptchaOnLoadCallbackExposed = value;
  }

  static get recaptchaScriptTagEnabled() {
    return recaptchaScriptTagEnabled;
  }

  static set recaptchaScriptTagEnabled(value) {
    recaptchaScriptTagEnabled = value;
  }

  static get recaptchaScriptTagSelector() {
    return recaptchaScriptTagSelector;
  }

  static set recaptchaScriptTagSelector(value) {
    recaptchaScriptTagSelector = value;
  }

  static get csrfTokenFetched() {
    return csrfTokenFetched;
  }

  static set csrfTokenFetched(value) {
    csrfTokenFetched = value;
  }

  static get csrfFetchInProgress() {
    return csrfFetchInProgress;
  }

  static set csrfFetchInProgress(value) {
    csrfFetchInProgress = value;
  }

  static get csrfHeaders() {
    return csrfHeaders;
  }

  static set csrfHeaders(value) {
    csrfHeaders = value;
  }

  static registerForm(form) {
    forms.push(form);
  }

  static csrfUrl() {
    return `${window.location.origin}/${csrfEndpoint}`;
  }

  // Each recaptcha instance needs an own uniquely named callback function. This function assures no two callback
  // functions have the same name.
  static uniqueRecaptchaCallbackName() {
    const randomize = () => {
      const rand = Math.random().toString(36).substr(2, 5);
      return `${rand[0].toUpperCase()}${rand.substr(1)}`;
    };

    const exists = (candidate) => forms.find((form) => form.recaptchaCallbackName === candidate);

    let name = `recaptchaCallbackForm${randomize()}`;
    while (exists(name)) {
      name = `recaptchaCallbackForm${randomize()}`;
    }
    return name;
  }

  renderRecaptcha() {
    // explicit recaptcha rendering (to support multiple instances)
    const recaptcha = this.recaptchaContainer();
    const attributes = {
      sitekey: recaptcha.dataset.sitekey,
      size: recaptcha.dataset.size,
      callback: recaptcha.dataset.callback,
    };

    this.recaptchaWidgetId = window.grecaptcha.render(recaptcha, attributes);
  }

  // Expose the global recaptcha load callback to the window object
  static exposeRecaptchaOnLoadCallback() {
    if (!Form.recaptchaOnLoadCallbackExposed) {
      window.onloadRecaptchaCallback = () => {
        Form.recaptchaOnLoadCallback();
      };
      Form.recaptchaOnLoadCallbackExposed = true;
    }
  }

  // We're only loading recaptcha source code when it's actually needed, by copying the [data-src] value of the script
  // tag to [src]
  static enableRecaptchaScriptTag() {
    if (!Form.recaptchaScriptTagEnabled) {
      const script = document.querySelector(this.recaptchaScriptTagSelector);
      if (!script.getAttribute('src')) {
        script.setAttribute('src', script.dataset.src);
      }
      Form.recaptchaScriptTagEnabled = true;
    }
  }

  // Once recaptcha is loaded, we need to prepare all instances. This is why we registered all instances before. We
  // can now trigger each one of them to prepare their recaptcha instance.
  static recaptchaOnLoadCallback() {
    forms.forEach((form) => {
      form.renderRecaptcha();
    });
    Form.recaptchaOnLoadCallbackCalled = true;
  }

  static async fetchCsrfToken() {
    if (this.csrfTokenFetched || this.csrfFetchInProgress) {
      return;
    }

    const response = await this.sendCsrfRequest();
    this.csrfFetchInProgress = false;
    let json;

    try {
      json = await response.json();
    } catch (e) {
      this.handleFailure(response.status);
      return;
    }

    if (response.ok) {
      this.csrfHeaders = json.headers;
      this.csrfTokenFetched = true;
    } else {
      this.handleFailure(response.status, json);
    }
  }

  static async sendCsrfRequest() {
    Form.csrfFetchInProgress = true;
    return fetch(Form.csrfUrl(), {
      method: csrfMethod,
    });
  }

  bindListeners() {
    this.form.addEventListener('submit', (ev) => this.lInteraction(ev));
    this.form.addEventListener('submit', (ev) => this.lSubmit(ev));
    this.form.addEventListener('focusin', (ev) => this.lInteraction(ev));
    this.form.addEventListener('input', (ev) => this.lInteraction(ev));
    this.form.addEventListener('change', (ev) => this.lInteraction(ev));
  }

  async lSubmit(ev) {
    // Indicate to the user that the form is being executed by giving submit buttons the "disabled" attribute and a
    // CSS class which should be styled to visually indicate a loading state.
    this.setState(true);

    // Cancel the event. In case recaptcha is used the instance has to be executed first. This triggers
    // this.recaptchaCallback() which will actually submit the form. In case of XHR, there never is an actual submit
    // as we're handling that asynchronously
    if (this.recaptcha || this.xhr) {
      ev.preventDefault();
    }

    if (this.recaptcha || this.fetchCsrf) {
      let loading = false;

      // The recaptcha source may not have finished loading, wait until our callback was called
      if (this.recaptcha && !Form.recaptchaOnLoadCallbackCalled) {
        loading = true;
        Form.enableRecaptchaScriptTag();
      }

      if (this.fetchCsrf && !Form.csrfTokenFetched) {
        loading = true;

        // Initiate the request if it's not pending
        if (!Form.csrfFetchInProgress) {
          Form.fetchCsrfToken();
        }
      }

      if (loading) {
        setTimeout(() => {
          // This function keeps calling itself every 100ms until both recaptcha and CSRF token are ready
          this.lSubmit(ev);
        }, 100);
        return this;
      }
    }

    // Now we're sure all required stuff has been loaded

    if (this.recaptcha) {
      const input = this.recaptchaInput();
      if (input && input.value !== '') {
        // Recaptcha binds itself to the global window object
        window.grecaptcha.reset(this.recaptchaWidgetId);
      }

      // Execute the recaptcha instance. This will trigger this.recaptchaCallback() once done.
      window.grecaptcha.execute(this.recaptchaWidgetId).catch(() => {
        // Recaptcha failed to execute, check your keys
        this.showGeneralError();

        // Re-enable the submit buttons, remove the loader class from the submit buttons.
        this.setState(false);
        return false;
      });
      return this;
    }

    // If we got here, we're dealing with a XHR request that does not require recaptcha. We can simply invoke the
    // execute function.
    if (this.xhr) {
      await this.executeXhr();
    }

    return this;
  }

  // We only enable the script tag loading the recaptcha source if the user interacts with the form. This listener
  // handles this.
  lInteraction() {
    if (this.recaptcha) {
      Form.enableRecaptchaScriptTag();
    }

    if (this.fetchCsrf) {
      Form.fetchCsrfToken();
    }
  }

  // This gets called once window.grecaptcha.execute() is done. The recaptcha response is now available, so we're good
  // to actually submit.
  async recaptchaCallback() {
    if (this.xhr) {
      await this.executeXhr();
      return this;
    }

    this.form.submit();
    return this;
  }

  async executeXhr() {
    const response = await this.sendXhr();

    let json;
    try {
      json = await response.json();
    } catch (e) {
      this.handleFailure(response.status);
      this.setState(false);
      return;
    }

    this.setState(false);

    if (response.ok) {
      this.handleSuccess(json);
    } else {
      this.handleFailure(response.status, json);
    }
  }

  action() {
    const action = this.form.getAttribute('action');
    return action || window.location;
  }

  method() {
    const method = this.form.getAttribute('method');
    return method || 'GET';
  }

  resultContainer() {
    return this.form.querySelector(this.resultSelector);
  }

  recaptchaContainer() {
    return this.form.querySelector(`.${this.recaptchaClass}`);
  }

  recaptchaInput() {
    return this.form.querySelector(`[name="${this.recaptchaName}"]`);
  }

  handleFailure(status, data) {
    if (status === 422 && data) {
      // HTTP 422 means a server-side validation error
      this.showValidationErrors(data);
    } else {
      // Something else went wrong. We don't know any details, so we show the global catch-all error message and be
      // done with it.
      this.showGeneralError();
    }
  }

  handleSuccess(data) {
    const { result } = data;

    if (this.propagateGtm) {
      this.propagateGtmEvent();
    }

    if (result) {
      this.showSuccessMessage(result);
    } else {
      this.showGeneralSuccess();
    }
  }

  showValidationErrors(response) {
    const { result } = response;
    this.resultContainer().innerHTML = result;
  }

  showGeneralError() {
    this.resultContainer().innerHTML = `
      <div class="note note--error">
        <p>${this.generalErrorMessage}</p>
      </div>`;
  }

  showGeneralSuccess() {
    this.resultContainer().innerHTML = `
    <div class="note note--success">
      <p>${this.generalSuccessMessage}</p>
    </div>`;
  }

  showSuccessMessage(successMarkup) {
    if (this.replaceFormOnSuccess) {
      this.form.outerHTML = successMarkup;
    } else {
      this.resultContainer().innerHTML = successMarkup;
    }
  }

  // Propagate the formSubmission event to GTM
  propagateGtmEvent() {
    const formId = this.form.id;
    window.dataLayer.push({
      event: 'formSubmission',
      formTitle: document.title,
      formLabel: formId,
    });
  }

  data() {
    return serialize(this.form, { hash: true });
  }

  xhrBody() {
    if (!this.jsonEncodedRequest) {
      return new FormData(this.form);
    }

    return JSON.stringify(this.data());
  }

  xhrHeaders() {
    const headers = {
      Accept: 'application/json',
    };

    if (this.fetchCsrf) {
      Object.keys(Form.csrfHeaders).forEach((headerName) => {
        headers[headerName] = Form.csrfHeaders[headerName];
      });
    }

    if (this.jsonEncodedRequest) {
      headers['Content-Type'] = 'application/json';
    }
    return headers;
  }

  setState(activeState) {
    this.active = activeState;
    this.setSubmitButtonsActive(activeState);
  }

  async sendXhr() {
    return fetch(this.action(), {
      method: this.method(),
      headers: this.xhrHeaders(),
      body: this.xhrBody(),
    });
  }

  setSubmitButtonsActive(activeState) {
    const buttons = this.form.querySelectorAll(this.buttonSelector);
    buttons.forEach((button) => {
      this.setButtonLoadingClass(button, activeState);
      this.setButtonDisabled(button, activeState);
    });
    return this;
  }

  setButtonLoadingClass(button, activeState) {
    const buttonHasLoadingClass = button.classList.contains(this.buttonLoadingClass);
    if (activeState && !buttonHasLoadingClass) {
      button.classList.add(this.buttonLoadingClass);
    } else if (!activeState && buttonHasLoadingClass) {
      button.classList.remove(this.buttonLoadingClass);
    }
    return this;
  }

  setButtonDisabled(button, activeState) {
    const buttonIsDisabled = button.hasAttribute('disabled');
    if (activeState && !buttonIsDisabled) {
      button.setAttribute('disabled', '');
    } else if (!activeState && buttonIsDisabled) {
      button.removeAttribute('disabled');
    }
    return this;
  }

  // Write the unique recaptcha callback name to the data-callback attribute on the recaptcha container. That's how
  // recaptcha knows what function to call once done executing.
  bindRecaptchaCallback() {
    this.recaptchaContainer().dataset.callback = this.recaptchaCallbackName;
    return this;
  }

  // We're binding our recaptcha callback on the global window object so it can be called by recaptcha.
  exposeRecaptchaCallback() {
    window[this.recaptchaCallbackName] = () => {
      this.recaptchaCallback();
    };
    return this;
  }
}

const formInit = (form, options = {}) => new Form(form, options);

export { formInit as default, formInit as form, Form }; // eslint-disable-line no-restricted-exports
