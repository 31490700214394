/* eslint-disable */
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';

export default function () {
  ScrollReveal().reveal('.scrollReveal__element', {
    duration: 800,
    easing: 'cubic-bezier(0.42, 0.0, 1.0, 1.0)',
    origin: 'bottom',
    distance: '50px',
  });
}
